pre[class*='language-'] {
  color: theme('colors.zinc.100');
}

.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.selector.class,
.token.function {
  color: theme('colors.pink.400');
}

.token.attr-name,
.token.keyword,
.token.rule,
.token.pseudo-class,
.token.important {
  color: theme('colors.zinc.300');
}

.token.module {
  color: theme('colors.pink.400');
}

.token.attr-value,
.token.class,
.token.string,
.token.property {
  color: theme('colors.teal.300');
}

.token.punctuation,
.token.attr-equals {
  color: theme('colors.zinc.500');
}

.token.unit,
.language-css .token.function {
  color: theme('colors.sky.200');
}

.token.comment,
.token.operator,
.token.combinator {
  color: theme('colors.zinc.400');
}

:root {
  --shiki-color-text: theme('colors.white');
  --shiki-token-constant: theme('colors.emerald.300');
  --shiki-token-string: theme('colors.emerald.300');
  --shiki-token-comment: theme('colors.zinc.500');
  --shiki-token-keyword: theme('colors.sky.300');
  --shiki-token-parameter: theme('colors.pink.300');
  --shiki-token-function: theme('colors.violet.300');
  --shiki-token-string-expression: theme('colors.emerald.300');
  --shiki-token-punctuation: theme('colors.zinc.200');
}
